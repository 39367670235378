<template>
  <div
    class="office-card animation"
    v-observe-visibility="{ callback: animation, once: true}"
  >
    <!-- Top -->
    <div class="header">
      <div class="text">{{ data?.City }}, {{ data?.Country }}</div>
      <div class="top-line"></div>
    </div>
    <div class="contacts">
      <div class="text">
        <a :href="`tel:${data?.Phone.replace(/ /gi, '')}`">{{ data?.Phone }}</a>
      </div>
      <div class="text">
        <a
          :href="`mailto:${data?.Email}`"
          target="_blank"
        >{{ data?.Email }}</a>
      </div>
    </div>

    <!-- Bottom -->
    <div class="bottom">
      <div class="bottom-text">{{ data.Address }}</div>
      <a
        :href="data.Link"
        class="map-link"
      >
        <span>{{ linkText }}</span>
        <Icon
          name="pin-outline"
          class="pin-icon"
        />
      </a>
      <div class="bottom-line"></div>
    </div>
  </div>
</template>

<script>
import Icon from '@/components/Icon.vue';
import { animationMixin } from '@/components/mixins/animation';

export default {
  name: 'OfficeCard',
  components: { Icon },
  mixins: [animationMixin],
  props: {
    data: {
      type: Object,
      required: true,
    },
    linkText: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.office-card {
  $b: &;
  width: 100%;
  display: flex;
  flex-direction: column;

  .text {
    font-weight: 700;
    line-height: 1.72;

    @include wRule(
      font-size,
      (
        xl: 30,
        lg: 25,
        md: 25,
        sm: 30,
      )
    );

    a {
      position: relative;

      &::before {
        @include pseudo;

        font-size: inherit;
        left: 0;
        bottom: -0.04em;
        width: 100%;
        height: 0.1em;
        background-color: currentColor;
        transition: transform 0.4s ease;
        transform: scaleX(0);
        transform-origin: 0 50%;
      }

      @media (hover: hover) {
        &:hover {
          &::before {
            transform: scaleX(1);
          }
        }
      }
    }
  }

  /*
  Top
   */
  .header {
    display: flex;
    align-items: center;
  }

  .contacts {
    margin-bottom: auto;
  }

  .top-line {
    border-top-style: solid;
    border-top-color: color(basic);
    flex: 1;

    @include wRule(
      margin-left,
      (
        xl: 30,
        lg: 30,
        md: 30,
        sm: 30,
      )
    );
    @include wRule(
      border-top-width,
      (
        xl: 3,
        lg: 3,
        md: 3,
        sm: 3,
      )
    );
  }

  /*
  Bottom
   */
  .bottom {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    line-height: 1.72;

    @include wRule(
      font-size,
      (
        xl: 20,
        lg: 18,
        md: 16,
        sm: 30,
      )
    );
    @include wRule(
      margin-top,
      (
        xl: 109,
        lg: 87,
        md: 66,
        sm: 79,
      )
    );
  }

  .pin-icon {
    @include wRule(
      width,
      (
        xl: 19,
        lg: 14,
        md: 12,
        sm: 19,
      )
    );
    @include wRule(
      margin-left,
      (
        xl: 13,
        lg: 24,
        md: 16,
        sm: 20,
      )
    );
  }

  .bottom-text {
    @include rule(
      flex,
      (
        xl: 1,
        lg: 0 auto,
      )
    );
    @include rule(
      width,
      (
        lg: 100%,
      )
    );
    @include wRule(
      margin-right,
      (
        xl: 40,
        lg: 0,
      )
    );
    @include wRule(
      margin-bottom,
      (
        lg: 11,
        md: 15,
        sm: 17,
      )
    );
  }

  .map-link {
    display: flex;
    align-items: center;
    font-weight: 700;

    @include rule(
      order,
      (
        sm: 100,
      )
    );

    span {
      position: relative;

      &::before {
        @include pseudo;

        font-size: inherit;
        left: 0;
        bottom: -0.04em;
        width: 100%;
        height: 0.1em;
        background-color: currentColor;
        transition: transform 0.4s ease;
        transform: scaleX(0);
        transform-origin: 0 50%;
      }
    }

    @media (hover: hover) {
      &:hover {
        span::before {
          transform: scaleX(1);
        }
      }
    }
  }

  .bottom-line {
    width: 100%;
    border-top-style: solid;
    border-top-color: color(basic);

    @include wRule(
      margin-top,
      (
        xl: 33,
        lg: 16,
        md: 8,
        sm: 0,
      )
    );
    @include wRule(
      border-top-width,
      (
        xl: 3,
        lg: 3,
        md: 3,
        sm: 3,
      )
    );

    @include screen(sm) {
      width: auto;
      flex: 1;
      margin-right: pxtovw(24, sm);
      align-self: center;
    }
  }

  &.animation {
    .header {
      opacity: 0;
      transform: translate3d(0, -20px, 0);
      transition: opacity 0.3s ease, transform 0.3s ease;
    }

    .top-line {
      transform-origin: 0 50%;
      transform: scaleX(0);
      transition: transform 0.3s ease;
    }

    .contacts {
      opacity: 0;
      transform: translate3d(0, -20px, 0);
      transition: opacity 0.3s ease, transform 0.3s ease;
    }

    .bottom {
      opacity: 0;
      transform: translate3d(0, -20px, 0);
      transition: opacity 0.3s ease, transform 0.3s ease;
    }

    .bottom-line {
      transform-origin: 100% 50%;
      transform: scaleX(0);
      transition: transform 0.3s ease;
    }
  }

  &.is-animated {
    @for $i from 1 to 3 {
      &:nth-child(#{$i}) {
        .header {
          opacity: 1;
          transform: translate3d(0, 0, 0);
          transition: opacity 0.5s ease, transform 0.5s ease;
          transition-delay: (0.1s * $i);
        }

        .top-line {
          transform: scaleX(1);
          transition: transform 0.5s ease 0.3s;
          transition-delay: 0.3s + (0.1s * $i);
        }

        .contacts {
          opacity: 1;
          transform: translate3d(0, 0, 0);
          transition: opacity 0.5s ease 0.1s, transform 0.5s ease 0.1s;
          transition-delay: 0.1s + (0.1s * $i);
        }

        .bottom {
          opacity: 1;
          transform: translate3d(0, 0, 0);
          transition: opacity 0.5s ease 0.3s, transform 0.5s ease 0.3s;
          transition-delay: 0.3s + (0.1s * $i);
        }

        .bottom-line {
          transform: scaleX(1);
          transition: transform 0.5s ease 0.3s;
          transition-delay: 0.3s + (0.1s * $i);
        }
      }
    }
  }
}
</style>
