<template>
  <div
    class="section-header"
    :class="{'has-margin-bottom': marginBottom}"
  >
    <div
      v-if="$slots.left || title"
      class="left"
    >
      <slot name="left">
        <h2
          class="title animation"
          v-if="title"
          v-html="title"
          v-observe-visibility="{ callback: animation, once: true}"
        />
      </slot>
    </div>
    <div
      v-if="$slots.right"
      class="right"
      :class="{'is-bottom-sm': rightBottomSM}"
    >
      <slot name="right"></slot>
    </div>
  </div>
</template>

<script>
import { animationMixin } from '@/components/mixins/animation';

export default {
  name: 'SectionHeader',
  mixins: [animationMixin],
  props: {
    title: {
      type: String,
    },
    rightBottomSM: {
      type: Boolean,
    },
    marginBottom: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.section-header {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  &.has-margin-bottom {
    @include wRule(
      margin-bottom,
      (
        xl: 120,
        lg: 77,
        md: 40,
        sm: 120,
      )
    );
  }

  .left {
    flex: 1;
  }

  .right {
    @include wRule(
      margin-left,
      (
        xl: 40,
        lg: 40,
        md: 40,
        sm: 40,
      )
    );

    &.is-bottom-sm {
      @include wRule(
        width,
        (
          sm: 100%,
        )
      );
      @include wRule(
        margin-left,
        (
          sm: 0,
        )
      );
      @include wRule(
        margin-top,
        (
          sm: 62,
        )
      );
    }
  }

  .title {
    line-height: 1.28;
    font-weight: 700;

    @include wRule(
      font-size,
      (
        xl: 65,
        lg: 50,
        md: 45,
        sm: 65,
      )
    );

    &.animation {
      opacity: 0;
      transform: translate3d(0, -20px, 0);
      transition: opacity 0.3s ease, transform 0.3s ease;
    }

    &.is-animated {
      opacity: 1;
      transform: translate3d(0, 0, 0);
      transition: opacity 0.5s ease 0.5s, transform 0.5s ease 0.5s;
    }
  }
}
</style>
