<template>
  <div
    class="person-card"
    :class="{ 'cursor-pointer': data?.Description }"
    @click.prevent="data?.Description ? showFullInfo : ''"
  >
    <div class="image-wrapper">
      <img
        v-if="data?.Photo.url"
        :src="getUrl(data?.Photo.url)"
        :alt="data?.Name"
        class="image"
      >
    </div>
    <div class="content">
      <h3 class="name">{{ data?.Name }}</h3>
      <h4 class="position">{{ data?.Position }}</h4>
      <div class="contacts">
        <p v-if="data?.Phone">
          <a
            @click.stop
            :href="`tel:${data?.Phone?.replace(/ /gi, '')}`"
          >{{ data?.Phone }}</a>
        </p>
        <p v-if="data?.Email">
          <a
            @click.stop
            :href="`mailto:${ data?.Email }`"
            target="_blank"
          >{{ data?.Email  }}</a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import getUrl from '@/tools/getUrl';

export default {
  name: 'PersonCard',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  emits: ['showFullInfo'],
  methods: {
    getUrl,
    showFullInfo() {
      this.$emit('showFullInfo', this.data);
    },
  },
};
</script>

<style lang="scss" scoped>
.person-card {
  &.cursor-pointer {
    cursor: pointer;

    &:hover {
      .image {
        image-rendering: pixelated;
        transform: scale(1.1);
      }

      .name {
        &::before {
          transform: scaleX(1);
        }
      }
    }
  }

  .image-wrapper {
    width: 100%;
    position: relative;
    padding-bottom: (397/345) * 100%;
    overflow: hidden;
  }

  .image {
    @include fill-img;

    bottom: auto;
    transition: transform 0.4s ease;
  }

  .content {
    @include wRule(
      margin-top,
      (
        xl: 45,
        lg: 31,
        md: 34,
        sm: 55,
      )
    );
  }

  .name {
    position: relative;
    display: inline-block;
    line-height: 1.8;
    font-weight: 700;

    &::before {
      @include pseudo;

      font-size: inherit;
      left: 0;
      bottom: 0.2em;
      width: 100%;
      height: 0.1em;
      background-color: currentColor;
      transition: transform 0.4s ease;
      transform: scaleX(0);
      transform-origin: 0 50%;
    }

    @include wRule(
      font-size,
      (
        xl: 30,
        lg: 25,
        md: 25,
        sm: 30,
      )
    );
  }

  .position {
    line-height: 1.5;
    color: color(gray);

    @include wRule(
      font-size,
      (
        xl: 16,
        lg: 14,
        md: 14,
        sm: 20,
      )
    );
  }

  .contacts {
    line-height: 1.4;

    @include wRule(
      margin-top,
      (
        xl: 23,
        lg: 22,
        md: 9,
        sm: 36,
      )
    );
    @include wRule(
      font-size,
      (
        xl: 20,
        lg: 18,
        md: 16,
        sm: 30,
      )
    );

    a {
      position: relative;

      &::before {
        @include pseudo;

        font-size: inherit;
        left: 0;
        bottom: -0.04em;
        width: 100%;
        height: 0.1em;
        background-color: currentColor;
        transition: transform 0.4s ease;
        transform: scaleX(0);
        transform-origin: 0 50%;
      }

      &:hover {
        &::before {
          transform: scaleX(1);
        }
      }
    }
  }
}
</style>
