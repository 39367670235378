<template>
  <article class="person-info">
    <div
      class="preview animation"
      v-observe-visibility="animation"
    >
      <img
        :src="getUrl(data?.Photo.url)"
        :alt="data?.Name"
      >
    </div>
    <div
      class="content animation"
      v-observe-visibility="animation"
    >
      <div
        class="description"
        v-html="markdownToHTML(data?.Description)"
      />

      <div
        class="about animation"
        v-observe-visibility="animation"
      >
        <h3>{{ data?.Name }}</h3>
        <p>{{ data?.Position }}</p>
      </div>

      <div
        class="contacts animation"
        v-observe-visibility="animation"
      >
        <p v-if="data?.Email">
          <a
            :href="`mailto:${data?.Email}`"
            target="_blank"
          >{{data.Email}}</a>
        </p>
      </div>
    </div>
  </article>
</template>

<script>
import getUrl from '@/tools/getUrl';
import { animationMixin } from '@/components/mixins/animation';

const md = require('markdown-it')({
  html: true,
  linkify: true,
  typographer: true,
});

export default {
  name: 'PersonInfo',
  mixins: [animationMixin],
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getUrl,
    markdownToHTML(content) {
      return md.render(content);
    },
  },
};
</script>

<style lang="scss" scoped>
.person-info {
  display: flex;
  width: 100%;
  color: color(basic);
  box-sizing: border-box;
  border: none;
  padding: 0;
  margin: 0;

  @include wRule(
    max-width,
    (
      xl: 1394,
      lg: 986,
      md: 774,
      sm: 550,
    )
  );

  @include rule(
    flex-direction,
    (
      xl: row,
      sm: column,
    )
  );

  .description {
    ::v-deep(ul) {
      display: block;

      li {
        position: relative;
        display: block;
        box-sizing: border-box;

        @include wRule(
          padding-left,
          (
            xl: 72,
            lg: 54,
            md: 38,
            sm: 75,
          )
        );

        &::before {
          position: absolute;
          top: 0;
          left: 0;
          display: block;
          content: "";
          background: currentColor;
          margin-top: 0.7em;

          @include wRule(
            width,
            (
              xl: 20,
              lg: 15,
              md: 11,
              sm: 20,
            )
          );

          @include wRule(
            height,
            (
              xl: 3,
              lg: 2,
              md: 2,
              sm: 3,
            )
          );
        }
      }
    }

    ::v-deep(> :not(:last-child)) {
      @include wRule(
        margin-bottom,
        (
          xl: 16,
          lg: 15,
          md: 12,
          sm: 24,
        )
      );
    }
  }

  a {
    position: relative;

    &::before {
      @include pseudo;

      font-size: inherit;
      left: 0;
      bottom: -0.04em;
      width: 100%;
      height: 0.1em;
      background-color: currentColor;
      transition: transform 0.4s ease;
      transform: scaleX(0);
      transform-origin: 0 50%;
    }

    &:hover {
      &::before {
        transform: scaleX(1);
      }
    }
  }

  h3 {
    line-height: 1.2;
    font-weight: 700;

    @include wRule(
      font-size,
      (
        xl: 30,
        lg: 25,
        md: 25,
        sm: 30,
      )
    );
  }

  .preview {
    position: relative;
    display: block;
    overflow: hidden;
    flex: none;
    font-size: 0;
    line-height: 0;

    @include wRule(
      width,
      (
        xl: 568,
        lg: 402,
        md: 316,
      )
    );

    @include screen("sm", max) {
      width: 100%;
      height: pxtovw(571, sm);
    }

    img {
      font-size: 0;
      line-height: 0;

      @include fill-img;

      bottom: auto;
    }

    &.animation {
      opacity: 0;
      transition: opacity 0.3s ease;
    }

    &.is-animated {
      opacity: 1;
      transition: opacity 0.5s ease 0.5s;
    }
  }

  .content {
    display: block;
    box-sizing: border-box;
    flex: 1;
    background-color: color(basic-light);

    @include rule(
      line-height,
      (
        xl: 1.6,
        lg: 1.4,
      )
    );

    @include wRule(
      padding,
      (
        xl: 189 168 166 110,
        lg: 100 100 90 77,
        md: 110 61 100,
        sm: 60 40 74,
      )
    );

    &.animation {
      opacity: 0;
      transform: translate3d(0, -20px, 0);
      transition: opacity 0.3s ease, transform 0.3s ease;
    }

    &.is-animated {
      opacity: 1;
      transform: translate3d(0, 0, 0);
      transition: opacity 0.5s ease 0.3s, transform 0.5s ease 0.3s;
    }
  }

  .about {
    line-height: 1.4;

    &:not(:first-child) {
      @include wRule(
        margin-top,
        (
          xl: 68,
          lg: 52,
          md: 44,
          sm: 66,
        )
      );
    }

    &.animation {
      opacity: 0;
      transform: translate3d(0, -20px, 0);
      transition: opacity 0.3s ease, transform 0.3s ease;
    }

    &.is-animated {
      opacity: 1;
      transform: translate3d(0, 0, 0);
      transition: opacity 0.5s ease 0.5s, transform 0.5s ease 0.5s;
    }
  }

  .contacts {
    line-height: 1.4;

    &:not(:first-child) {
      @include wRule(
        margin-top,
        (
          xl: 18,
          lg: 22,
          md: 14,
          sm: 12,
        )
      );
    }

    &.animation {
      opacity: 0;
      transform: translate3d(0, -20px, 0);
      transition: opacity 0.3s ease, transform 0.3s ease;
    }

    &.is-animated {
      opacity: 1;
      transform: translate3d(0, 0, 0);
      transition: opacity 0.5s ease 0.7s, transform 0.5s ease 0.7s;
    }
  }
}
</style>
