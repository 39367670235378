<template>
  <section class="contacts-map">
    <div
      class="wrapper animation"
      v-observe-visibility="{ callback: animation, once: true}"
    >
      <img
        src="@/assets/contacts/contacts-map.jpg"
        class="map-image"
        alt="@@"
      >
      <img
        src="@/assets/contacts/planes.png"
        class="map-image map-image_plane"
        alt="@@"
      >
      <div class="pins">
        <Popover
          class="pin animation"
          v-for="(pin, pinIndex) in pins"
          :key="pinIndex"
          :style="{ left: `${pin.PositionX}%`, top: `${pin.PositionY}%`}"
          :pinId="pinIndex"
          :currentHovered="currentHovered"
          @pin-hover="currentHovered = pinIndex"
        >
          <!-- Button -->
          <template v-slot:default="{ isOpen }">
            <button
              class="pin-button"
              :class="{ 'is-open': isOpen }"
              @click="openPopover(pin)"
            >
              <Icon
                name="pin"
                class="icon-pin"
              />
              <div class="pin-name">{{ pin?.City }}</div>
            </button>
          </template>

          <!-- Popover -->
          <template #popover>
            <OfficePopover
              :data="pin"
              :linkText="linkText"
            />
          </template>
        </Popover>
      </div>
    </div>
  </section>
  <teleport
    to="#popups"
    :disabled="breakpoint !== 'sm'"
  >
    <transition name="fade">
      <OfficePopover
        :data="currentOpened"
        :linkText="linkText"
        v-if="isOpened"
        @close-popover="closePopover"
      />
    </transition>
  </teleport>
</template>

<script>
import Icon from '@/components/Icon.vue';

// Popover
import OfficePopover from '@/views/Contacts/components/OfficePopover.vue';
import Popover from '@/components/Popover.vue';
import { animationMixin } from '@/components/mixins/animation';
import { freeze, unfreeze } from '@/tools/blockScroll';

export default {
  name: 'ContactsMap',
  components: {
    Popover,
    OfficePopover,
    Icon,
  },
  mixins: [animationMixin],
  props: {
    pins: {
      type: Array,
      required: true,
    },
    linkText: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      currentHovered: null,
      isOpened: false,
      currentOpened: undefined,
    };
  },
  computed: {
    breakpoint() {
      return this.$store.getters.getBreakpoint;
    },
  },
  methods: {
    openPopover(pin) {
      if (this.breakpoint === 'sm') {
        this.isOpened = true;
        this.currentOpened = pin;
        freeze();
      } else {
        this.isOpened = false;
        this.currentOpened = undefined;
        unfreeze();
      }
    },
    closePopover() {
      if (this.breakpoint === 'sm') {
        this.isOpened = false;
        unfreeze();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.contacts-map {
  letter-spacing: -0.02em;

  .wrapper {
    position: relative;

    @include rule(
      left,
      (
        sm: -100%,
      )
    );
    @include wRule(
      width,
      (
        xl: 100%,
        sm: 1920,
      )
    );

    &.animation {
      opacity: 0;
      transform: translate3d(0, -20px, 0);
      transition: opacity 0.3s ease, transform 0.3s ease;

      .map-image_plane {
        opacity: 0;
        transform: scale(1.3);
        transition: opacity 0.3s ease, transform 0.3s ease;
      }
    }

    &.is-animated {
      opacity: 1;
      transform: translate3d(0, 0, 0);
      transition: opacity 0.3s ease 0.2s, transform 0.3s ease 0.2s;

      .map-image_plane {
        opacity: 1;
        transform: scale(1);
        transition: opacity 0.3s ease 0.3s, transform 0.8s ease 0.3s;
      }
    }
  }

  .map-image {
    position: relative;
    display: block;
    transform: translate3d(0, 0, 0);
    width: 100%;
    z-index: 0;

    &_plane {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }
  }

  .pins {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }

  .pin {
    position: absolute;
    display: inline-block;
    // z-index: 2;

    &.animation {
      opacity: 0;
      transition: opacity 0.3s ease;
    }

    &.is-animated {
      opacity: 1;
      transition: opacity 1s ease 0.8s;

      @for $i from 1 to 3 {
        &:nth-child(#{$i}) {
          transition-delay: 0.8s + (0.1s * $i);
        }
      }
    }
  }

  .pin-button {
    position: relative;
    color: color(basic-light);
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    &:hover,
    &.is-open {
      color: color(main);
    }
  }

  .icon-pin {
    display: block;
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);

    @include wRule(
      width,
      (
        xl: 23,
        lg: 23,
        md: 17,
        sm: 23,
      )
    );
  }

  .pin-name {
    position: absolute;
    left: 0;
    top: 0;
    transform: translateX(-50%);
    line-height: 1.28;
    font-weight: 700;

    @include wRule(
      font-size,
      (
        xl: 20,
        lg: 18,
        md: 16,
        sm: 30,
      )
    );
  }
}
</style>
