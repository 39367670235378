<template>
  <div
    class="popover"
    v-click-outside="onClickOutside"
    v-observe-visibility="{ callback: animation, once: true}"
  >
    <div
      @click="togglePopover"
      @mouseenter="onHover"
      @mouseleave="onUnHovered"
    >
      <slot :isOpen="(isOpen || isHovered) && currentHovered === pinId"></slot>
    </div>
    <transition :duration="transitionDuration">
      <div
        class="wrapper"
        v-if="(isOpen || isHovered) && currentHovered === pinId && breakpoint !== 'sm'"
      >
        <slot name="popover"></slot>
      </div>
    </transition>
  </div>
</template>

<script>
import { animationMixin } from '@/components/mixins/animation';

export default {
  name: 'Popover',
  mixins: [animationMixin],
  props: {
    currentHovered: {
      type: [String, Number],
    },
    pinId: {
      type: [String, Number],
    },
    closeOnClickOutside: {
      type: Boolean,
      default: true,
    },
    transitionDuration: {
      type: Number,
    },
  },
  data() {
    return {
      isOpen: false,
      isHovered: false,
    };
  },
  computed: {
    breakpoint() {
      return this.$store.getters.getBreakpoint;
    },
  },
  methods: {
    togglePopover() {
      this.isOpen = !this.isOpen;
    },
    onClickOutside() {
      if (this.breakpoint !== 'sm') {
        if (this.isOpen) this.isOpen = false;
      }
    },
    onHover() {
      if (this.breakpont !== 'sm') {
        this.isHovered = true;
        this.$emit('pinHover');
      } else {
        this.isHovered = false;
      }
    },
    onUnHovered() {
      this.isHovered = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.popover {
  position: relative;

  .wrapper {
    position: absolute;
    top: 100%;
    left: 0;
    transform: translateX(-50%);
    z-index: 10;

    @include wRule(
      margin-top,
      (
        xl: 30,
        lg: 30,
        md: 30,
        sm: 30,
      )
    );
  }
}
</style>
