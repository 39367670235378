<template>
  <div
    class="office-popover animation"
    v-observe-visibility="animation"
  >
    <close
      v-if="breakpoint==='sm'"
      @click.prevent="closePopover"
    />
    <div class="title">{{ data?.Title }}</div>
    <div class="description">{{ data?.Description }}</div>
    <a
      class="link"
      :href="data?.Link"
    >
      <span>{{ linkText }}</span>
      <Icon
        name="pin-outline"
        class="icon"
      />
    </a>
  </div>
</template>

<script>
import Icon from '@/components/Icon.vue';
import { animationMixin } from '@/components/mixins/animation';
import Close from '../../../components/Close.vue';

export default {
  name: 'OfficePopover',
  mixins: [animationMixin],
  components: { Icon, Close },
  emtis: ['closePopover'],
  props: {
    data: {
      type: Object,
      required: true,
    },
    linkText: {
      type: String,
      required: true,
    },
  },
  computed: {
    breakpoint() {
      return this.$store.getters.getBreakpoint;
    },
  },
  methods: {
    closePopover() {
      this.$emit('closePopover');
    },
  },
};
</script>

<style lang="scss" scoped>
.office-popover {
  display: block;
  background: color(bg);
  box-sizing: border-box;

  @include wRule(
    width,
    (
      xl: 340,
      lg: 340,
      md: 340,
      sm: 340,
    )
  );
  @include wRule(
    padding,
    (
      xl: 30 40 40,
      lg: 30 40 40,
      md: 30 40 40,
      sm: 80 45,
    )
  );

  @include screen("sm", max) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    overflow: auto;
  }

  .title {
    line-height: 1.72;
    font-weight: 700;

    @include wRule(
      font-size,
      (
        xl: 25,
        lg: 25,
        md: 25,
        sm: 30,
      )
    );

    @include wRule(
      max-width,
      (
        sm: 400,
      )
    );
  }

  .description {
    font-weight: 400;
    line-height: 1.2;

    @include wRule(
      margin-top,
      (
        xl: 21,
        lg: 21,
        md: 21,
        sm: 10,
      )
    );
    @include wRule(
      font-size,
      (
        xl: 25,
        lg: 25,
        md: 25,
        sm: 30,
      )
    );

    @include wRule(
      max-width,
      (
        sm: 400,
      )
    );
  }

  .link {
    position: relative;
    display: flex;
    align-items: center;
    line-height: 1.72;
    font-weight: 700;

    @include wRule(
      padding-bottom,
      (
        xl: 14,
        lg: 14,
        md: 14,
        sm: 14,
      )
    );
    @include wRule(
      margin-top,
      (
        xl: 24,
        lg: 24,
        md: 24,
        sm: 28,
      )
    );
    @include wRule(
      font-size,
      (
        xl: 18,
        lg: 18,
        md: 18,
        sm: 30,
      )
    );

    &::before {
      @include pseudo;

      font-size: inherit;
      left: 0;
      bottom: 0;
      width: 100%;
      background-color: currentColor;
      transition: transform 0.4s ease;
      transform-origin: 0 50%;

      @include wRule(
        height,
        (
          xl: 4,
          lg: 4,
          md: 4,
          sm: 4,
        )
      );

      @include screen("sm", max) {
        position: static;
        display: inline-block;
        margin-right: pxtovw(24, sm);
      }
    }

    @media (hover: hover) {
      &:hover {
        color: color(main);
      }
    }

    span {
      @include screen("sm", max) {
        flex: none;
      }
    }
  }

  .icon {
    flex: none;

    @include wRule(
      width,
      (
        xl: 14,
        lg: 14,
        md: 14,
        sm: 19,
      )
    );
    @include wRule(
      margin-left,
      (
        xl: 19,
        lg: 19,
        md: 19,
        sm: 20,
      )
    );
  }

  &.animation {
    opacity: 0;
    transition: opacity 0.3s ease;

    .title,
    .description,
    .link {
      opacity: 0;
      transform: translate3d(0, 20px, 0);
      transition: opacity 0.3s ease, transform 0.3s ease, color 0.3s ease;
    }

    .link {
      &::before {
        transform: scaleX(0);
      }
    }
  }

  &.is-animated {
    opacity: 1;
    transition: opacity 0.3s ease;

    .title,
    .description,
    .link {
      opacity: 1;
      transform: translate3d(0, 0, 0);
      transition: opacity 0.3s ease, transform 0.3s ease, color 0.3s ease;
    }

    .title {
      transition-delay: 0.15s;
    }

    .description {
      transition-delay: 0.25s;
    }

    .link {
      transition: opacity 0.3s ease 0.35s, transform 0.3s ease 0.35s,
        color 0.3s ease;

      &::before {
        transform: scaleX(1);
        transition: transform 0.3s ease 0.4s;
      }
    }
  }

  .close {
    position: absolute;
    top: pxtovw(22, sm);
    right: pxtovw(35, sm);
  }
}
</style>
