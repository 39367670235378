<template>
  <div class="contacts page-container">
    <breadcrumbs :bottom-margin="true"></breadcrumbs>
    <ContactsMap
      :pins="offices"
      :linkText="pageContent?.OfficeLinkText"
    />
    <section class="offices">
      <div class="container">
        <div class="office-grid">
          <div
            v-for="office in offices"
            :key="office?.City"
            class="office-col"
          >
            <OfficeCard
              :data="office"
              :linkText="pageContent?.OfficeLinkText"
            />
          </div>
        </div>
      </div>
    </section>
    <section class="team">
      <div class="container">
        <SectionHeader :title="pageContent?.TeamSectionName">
          <template #right>
            <SliderArrows
              :swiper="swiperInstance"
              theme="dark"
            />
          </template>
        </SectionHeader>
        <div
          class="team-content animation"
          v-observe-visibility="{ callback: animation, once: true}"
        >
          <Swiper
            :watchOverflow="true"
            @swiper="swiperInstance = $event"
            :slides-per-view="slidesPerView"
            :space-between="spaceBetween"
          >
            <SwiperSlide
              v-for="member in team"
              :key="member?.Name"
            >
              <PersonCard
                :data="member"
                @show-full-info="openPersonPopup"
              />
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </section>
    <teleport to="#popups">
      <transition name="fade-slide-up">
        <Popup
          v-if="personPopupActive"
          :isShown="personPopupActive"
          @modal-closed="personPopupActive = false"
        >
          <template #content>
            <PersonInfo :data="currentPerson" />
          </template>
        </Popup>
      </transition>
    </teleport>
  </div>
</template>

<script>
import Breadcrumbs from '../../components/Breadcrumbs.vue';
import ContactsMap from '@/views/Contacts/components/ContactsMap.vue';
import OfficeCard from '@/views/Contacts/components/OfficeCard.vue';
import SectionHeader from '@/components/SectionHeader.vue';
import PersonCard from '@/views/Contacts/components/PersonCard.vue';
import SliderArrows from '@/components/SliderArrows.vue';
import Popup from '@/components/Popup.vue';
import PersonInfo from '@/views/Contacts/components/PersonInfo.vue';
import { animationMixin } from '@/components/mixins/animation';

// Swiper
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/swiper.scss';

// API
import api from '@/tools/api';
import getUrl from '@/tools/getUrl';

export default {
  name: 'Contacts',
  mixins: [animationMixin],
  components: {
    SliderArrows,
    PersonCard,
    SectionHeader,
    OfficeCard,
    ContactsMap,
    Breadcrumbs,
    Swiper,
    SwiperSlide,
    Popup,
    PersonInfo,
  },
  data() {
    return {
      currentPerson: null,
      personPopupActive: false,
      swiperInstance: {},
      pageContent: {},
    };
  },
  computed: {
    breakpoint() {
      return this.$store.state.currentBreakpoint;
    },
    windowWidth() {
      return this.$store.state.windowWidth;
    },
    slidesPerView() {
      switch (this.breakpoint) {
        case 'lg':
          return 4;

        case 'md':
          return 3;

        case 'sm':
          return 1;

        default:
          return 4;
      }
    },
    spaceBetween() {
      switch (this.breakpoint) {
        case 'lg':
          return Math.floor((14 / 1366) * this.windowWidth);

        case 'md':
          return Math.floor((20 / 1024) * this.windowWidth);

        case 'sm':
          return Math.floor((32 / 640) * this.windowWidth);

        default:
          return Math.floor((20 / 1920) * this.windowWidth);
      }
    },
    offices() {
      return this.pageContent?.Offices;
    },
    team() {
      const team = this.pageContent?.Team;
      const sortedTeam = team?.sort((a, b) => a.Order - b.Order);
      return sortedTeam;
    },
    currentLang() {
      return this.$store.getters.getLang;
    },
  },
  methods: {
    openPersonPopup(person) {
      this.personPopupActive = true;
      this.currentPerson = person;
    },
    getUrl,
    async getPageContent() {
      this.pageContent = await api('page-contacts-content', true);
    },
  },
  watch: {
    currentLang() {
      this.getPageContent();
    },
  },
  beforeMount() {
    this.getPageContent();
  },
};
</script>

<style lang="scss" scoped>
.contacts {
  @include wRule(
    padding-top,
    (
      sm: 171,
    )
  );

  .offices {
    @include wRule(
      margin-top,
      (
        xl: 140,
        lg: 80,
        md: 47,
        sm: 100,
      )
    );
  }

  .office-grid {
    display: flex;
    flex-wrap: wrap;

    @include wRule(
      margin,
      (
        xl: -40 -28,
        lg: -49 -27,
        md: -37 -20,
        sm: -53,
      )
    );
  }

  .office-col {
    box-sizing: border-box;
    display: flex;

    @include wRule(
      padding,
      (
        xl: 40 28,
        lg: 49 27,
        md: 37 20,
        sm: 53,
      )
    );
    @include rule(
      width,
      (
        xl: 50%,
        sm: 100%,
      )
    );
  }

  .team {
    @include wRule(
      margin-top,
      (
        xl: 127,
        lg: 141,
        md: 89,
        sm: 150,
      )
    );

    .swiper-container {
      overflow: visible;

      @include wRule(
        padding-right,
        (
          sm: 130,
        )
      );
    }
  }

  .team-content {
    &.animation {
      opacity: 0;
      transform: translate3d(0, -20px, 0);
      transition: opacity 0.3s ease, transform 0.3s ease;
    }

    &.is-animated {
      opacity: 1;
      transform: translate3d(0, 0, 0);
      transition: opacity 0.5s ease 0.5s, transform 0.5s ease 0.5s;
    }
  }
}
</style>
